<template>
	<Toast></Toast>
	<div>welcome</div>
	<!-- <div class="middle" :style="backgroundDiv">
		<div style='background-color: rgba(255, 255, 255, 0.6);margin: 30px;'
			class="surface-card p-6 shadow-2 border-round w-full lg:w-4">
			<div class="text-center mb-5">
				<img src="images/blocks/logos/hyper.svg" alt="Image" height="50" class="mb-3">
				<div class="text-900 text-3xl font-medium mb-3">空间数字图谱构建系统</div>
				<span class="text-600 font-medium line-height-3">注册</span>
				<a class="font-medium no-underline ml-2 text-blue-500 cursor-pointer"
					title='Email:2032006@tongji.edu.cn'>请发送邮件申请</a>
			</div>

			<div>
				<label for="email1" class="block text-900 font-medium mb-2">邮箱</label>
				<InputText id="email1" type="text" v-model='email' class="w-full mb-3" />

				<label for="password1" class="block text-900 font-medium mb-2">密码</label>
				<InputText id="password1" v-on:keyup.enter="login" v-model='password' type="text" class="w-full mb-3" />

				<div class="flex align-items-center justify-content-between mb-6">
					<div class="flex align-items-center">
						<Checkbox id="rememberme1" :binary="true" v-model="checked" class="mr-2"></Checkbox>
						<label for="rememberme1">自动登录</label>
					</div>
					<a class="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer"
						title='请联系2032006@tongji.edu.cn'>忘记密码?</a>
				</div>

				<Button @click="login" label="立即登录" icon="pi pi-user" class="w-full"></Button>
			</div>
		</div>
	</div> -->
</template>

<script>
	import NetWorkService from '../service/NetWorkService.js';
	export default {
		data() {
			return {
				checked: true,
				email: '',
				password: '',
				has_cookie: false,
				backgroundDiv: {
					// backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/kg-backimg.jpg)',
					// backgroundImage: 'url(https://fireflycos.libertynlp.com/kg-static/qiuye-2.jpg)',
				}
			}
		},
		created() {
			this.networkService = new NetWorkService();
		},
		mounted() {
			// this.readUserInfo();
			console.log('链接',this.$route.fullPath);
		},
		methods: {
			
		}

	}
</script>

<style scoped>
	.middle {
		height: 100%;
		position: fixed;
		width: 100%;
		background: no-repeat center top;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.login-container {
		display: flex;
		width: 340px;
		flex-direction: column;
		padding: 20px;
		border-radius: 10px;
		/* background-color:#55585a+7a; */
		background-color: rgba(255, 255, 255, 0.4);
		justify-content: center;
	}

	.login-lable {
		color: #000000;
		opacity: 0.6;
		font-weight: 700;
	}

	.input-container {
		display: flex;
		flex-direction: column;
		margin-top: 20px;
	}
</style>
